var geocoder;
var map;
var markers = [];
var bounds;
var filterCircle;
var infoWindow;

var viewModel = {

    // Paging    
    PageNumber: ko.observable(history.state && history.state.page ? history.state.page : 1), // When we come back from a profile page, history.state.page remembers the page number we were on
    PageSize: ko.observable(pageSize),
    Loading: ko.observable(false),
    FiltersApplied: ko.observable(false),

    // Static Content
    DeepLinkUrl: ko.observable(window.location.href),
    HasPreFilteredContentChanges: ko.observable(hasPreFilteredContentChanges),
    StructureFieldName: ko.observable(structureFieldName),   // Either "Member Type" or the name of a Custom Field, or blank if no structure present
    StructureFieldValue: ko.observable(structureFieldValue), // Either a Member Type's name or an option of a Custom Field

    // Results
    Members: ko.observableArray([]),
    TotalCount: ko.observable(null),

    // Filters
    FilterToggle: ko.observable(false),
    SearchText: ko.observable(searchText),
    MemberTypeIDs: ko.observable(memberTypeIDs),
    SpecialOffer: ko.observable(specialOffer),
    City: ko.observable(city),
    State: ko.observable(state),
    Zip: ko.observable(zip),
    County: ko.observable(county),
    Country: ko.observable(country),

    // Map View
    MapView: ko.observable(mapView),
    Latitude: ko.observable(latitude),
    Longitude: ko.observable(longitude),
    Radius: ko.observable(radius),
    Address: ko.observable(address)
};

viewModel.PageCount = ko.pureComputed(function () { return Math.ceil(viewModel.TotalCount() / viewModel.PageSize()); });

viewModel.MemberTypeIDs.subscribe(function () { reloadListing(); });
viewModel.SpecialOffer.subscribe(function () { reloadListing(); });
ko.computed(viewModel.City).extend({ throttle: 500 }).subscribe(function () { reloadListing(); });
ko.computed(viewModel.State).extend({ throttle: 500 }).subscribe(function () { reloadListing(); });
ko.computed(viewModel.Zip).extend({ throttle: 500 }).subscribe(function () { reloadListing(); });
ko.computed(viewModel.County).extend({ throttle: 500 }).subscribe(function () { reloadListing(); });
ko.computed(viewModel.Country).extend({ throttle: 500 }).subscribe(function () { reloadListing(); });

function filtersApplied() {
    return viewModel.SearchText() > ""
        || viewModel.MemberTypeIDs() > ""
        || viewModel.SpecialOffer() > ""
        || viewModel.City() > ""
        || viewModel.State() > ""
        || viewModel.Zip() > ""
        || viewModel.County() > ""
        || viewModel.Country() > ""
        || viewModel.Latitude() != null
        || viewModel.Longitude() != null
        || viewModel.Address() > ""
        || $.makeArray($(".custom-filter")).some(function (e) { return $(e).val() > ""; })
        ? true : false;
}

function goToPage(pageNumber) {
    viewModel.PageNumber(pageNumber);
    reloadListing(true);
}

function resetSearch() {
    viewModel.PageNumber(1);
    viewModel.SearchText("");
    viewModel.MemberTypeIDs("");
    var memberTypeInput = $(':input[name="MemberType"]');
    if (memberTypeInput.length) {
        memberTypeInput.selectize()[0].selectize.clear();
    }
    viewModel.SpecialOffer(null);
    viewModel.City("");
    viewModel.State("");
    viewModel.Zip("");
    viewModel.County("");
    viewModel.Country("");
    viewModel.Latitude(null);
    viewModel.Longitude(null);
    viewModel.Radius("50");
    viewModel.Address("");

    $(".custom-field-multi[id^='CustomField_']").each(function (i, obj) {
        $("#" + obj.id).selectize()[0].selectize.clear();
    });

    $(".custom-filter").each(function () {
        $(this).val("").change();
    });

    viewModel.StructureFieldName('');
    viewModel.StructureFieldValue('');

    viewModel.DeepLinkUrl(GetDeepLinkUrl());

    reloadListing();
}

viewModel.StructureFieldValueForUrl = ko.computed(function () {
    return seoFilenameMappings ? seoFilenameMappings[viewModel.StructureFieldValue()] : "";
});

function GetDeepLinkUrl() {
    var url = baseUrl;

    let structureFieldValueForUrl = viewModel.StructureFieldValueForUrl();
    if (structureFieldValueForUrl) {
        url += `/${structureFieldValueForUrl}`;
    }

    // Don't append member type to the query string if the url already specifies a member type
    if (viewModel.StructureFieldName() !== "Member Type") {
        url += GetQueryForFilter(url, "MemberTypeIDs");
    }

    url += GetQueryForFilter(url, "SearchText");
    url += GetQueryForFilter(url, "SpecialOffer");
    url += GetQueryForFilter(url, "City");
    url += GetQueryForFilter(url, "State");
    url += GetQueryForFilter(url, "Zip");
    url += GetQueryForFilter(url, "County");
    url += GetQueryForFilter(url, "Country");

    if (viewModel.MapView()) {
        url += GetQueryForFilter(url, "MapView");

        if (viewModel.Latitude() && viewModel.Longitude()) {
            url += GetQueryForFilter(url, "Address");
            url += GetQueryForFilter(url, "Latitude");
            url += GetQueryForFilter(url, "Longitude");
            url += GetQueryForFilter(url, "Radius");
        }
    }

    $(".custom-filter").each(function () {
        if (!$(this).data("structured")) {
            url += GetQueryForFilter(url, $(this).attr('name'));
        }
    });

    return url;
};

viewModel.DeepLinkUrl.subscribe(updatePageState);
viewModel.PageNumber.subscribe(updatePageState);
function updatePageState() {
    history.replaceState({ page: viewModel.PageNumber() }, null, viewModel.DeepLinkUrl()); // Update URL in address bar and "remember" which page we were on in case we come back
}

function GetQueryForFilter(url, name) {
    var val = viewModel.hasOwnProperty(name) ? viewModel[name]() : $("#" + name).val();
    if (val && val !== "null") {
        return (url.indexOf("?") == -1 ? "?" : "&") + name + "=" + encodeURIComponent(val);
    }

    return "";
}

var reloadListing = debounce(function (preservePageNumber) {
    viewModel.Loading(true);
    viewModel.FiltersApplied(filtersApplied());
    if (!preservePageNumber) {
        viewModel.PageNumber(1);
    }

    // maybe use ko.toJSON() ?
    var data = {
        "directoryID": directoryID,
        "pageNumber": viewModel.PageNumber(),
        "searchText": viewModel.SearchText(),
        "memberTypeIDs": viewModel.MemberTypeIDs(),
        "specialOffer": viewModel.SpecialOffer(),
        "city": viewModel.City(),
        "state": viewModel.State(),
        "zip": viewModel.Zip(),
        "county": viewModel.County(),
        "country": viewModel.Country(),
        "mapView": viewModel.MapView(),
        "latitude": viewModel.Latitude(),
        "longitude": viewModel.Longitude(),
        "radius": viewModel.Radius(),
    };

    $(".custom-filter").each(function () {
        if ($(this).val()) {
            data[$(this).attr('id')] = $(this).val();
        }
    });

    $.ajax({
        type: "POST",
        url: memberListHtmlUrl,
        data: data,
        dataType: "json",
        success: function (data) {
            if (data.Status === "OK") {
                var srInfo = '<span class="sr-only" role="status" aria-live="polite">' + data.Members.length + ' found from search</span>';
                $("#members-container").html(srInfo + data.Html);
                viewModel.Members(data.Members);
                viewModel.TotalCount(data.TotalCount);
                viewModel.DeepLinkUrl(GetDeepLinkUrl());
                viewModel.Loading(false);

                if (viewModel.MapView()) {
                    LoadMaps();
                }
            }
            else {
                ShowCustomAlert('There was an error loading the directory: ' + data.Status);
                viewModel.Loading(false);
            }
        },
        error: function (jqXHR, textStatus) {
            if (textStatus === 'abort' || jqXHR.status === 0)
                return;

            if (jqXHR && jqXHR.responseJSON) {
                var apiErrorResponse = jqXHR.responseJSON;
                ShowCustomAlert(apiErrorResponse.Message + (apiErrorResponse.ErrorDetails ? "<br/><br/> Error Details: <br/>" + apiErrorResponse.ErrorDetails : ""));
            }
            else {
                ShowCustomAlert('There was an error loading the directory: ' + jqXHR.responseText);
            }
            viewModel.IsLoading(false);
        }
    });
}, 300);

// When the page structure changes, we need to update the static regions and then fetch the new content
viewModel.StructureFieldValue.subscribe(function (newValue) {
    // Look for <a> or <button> elements with the 'editor-save' class and a 'data-component-id' attribute matching the staticPageID
    let selector = `.editor-save[data-component-id='${staticPageID}']`;
    let saveButtons = $(`a${selector},button${selector}`);

    // Add the current filter value, if any, to the elements as a 'data-filter' attribute
    saveButtons.attr("data-filter", newValue);
    reloadStaticContent();
});

function ResetStaticContentRegions() {
    viewModel.Loading(true);

    $.ajax({
        type: "POST",
        url: resetContentUrl,
        data: {
            "directoryID": directoryID,
            "filter": viewModel.StructureFieldValue()
        },
        dataType: "json",
        success: function (data) {
            if (data.Status === "Invalid") {
                ShowCustomAlert('There was an error resetting the content: ' + data.Errors);
            }
            else {
                if (data.BaseRegions) {
                    data.BaseRegions.forEach(function (region) {
                        setStaticContent(region.RegionName, region.Html);
                    });
                }

                viewModel.HasPreFilteredContentChanges(false);
                viewModel.Loading(false);
                ShowSavedPopup('The content regions were reset successfully.', '');
            }
        },
        error: function (jqXHR, textStatus) {
            if (textStatus === 'abort' || jqXHR.status === 0)
                return;

            if (jqXHR && jqXHR.responseJSON) {
                var apiErrorResponse = jqXHR.responseJSON;
                ShowCustomAlert(apiErrorResponse.Message + (apiErrorResponse.ErrorDetails ? "<br/><br/> Error Details: <br/>" + apiErrorResponse.ErrorDetails : ""));
            }
            else {
                ShowCustomAlert('There was an error resetting the content: ' + jqXHR.responseText);
            }
            viewModel.Loading(false);
        }
    });
}

function reloadStaticContent() {
    $.ajax({
        type: "POST",
        url: fetchContentUrl,
        data: {
            "directoryID": directoryID,
            "filter": viewModel.StructureFieldValue()
        },
        dataType: "json",
        success: function (data) {
            if (data && data.Regions) {
                data.Regions.forEach(function (region) {
                    setStaticContent(region.RegionName, region.Html);
                });

                viewModel.HasPreFilteredContentChanges(data.HasPreFilteredContentChanges);
            }
        }
    });
}

function setStaticContent(region, content) {

    const regionReplacedEvent = new CustomEvent(window.noviCmsNamespace.contentRegionReplacedEvent, { detail: { region: region, content: content } });
    document.dispatchEvent(regionReplacedEvent);

    // We need to set the visible content for standard users
    if (window.noviCmsNamespace.isPageEditToggled) {
        return;
    }

    // Otherwise we're using the old editor so we update the editable regions and viewable regions

    // Fetch the static region - its structure is different depending on whether the region is editable
    let tinyRegion = $(`#editable_${region}`); // For admins
    if (!tinyRegion.length) {
        tinyRegion = $(`[data-region="${region}"]`); // For standard users
    }

    if (tinyRegion && tinyRegion.length) {
        // New method for setting editor content
        if (typeof setEditorContent !== "undefined") {
            setEditorContent(region, content);
        }
        // Legacy method for setting editor content
        else if (typeof SetEditorContent !== "undefined") {
            SetEditorContent(region, content);
        }
        else {
            tinyRegion.html(content);
        }

        if (content) {
            tinyRegion.removeClass("empty");
        }
        else {
            tinyRegion.addClass("empty");
        }
    }
}

var addressPickerLoaded = false;

function LoadMaps() {
    var userPosition = {
        lat: parseFloat(viewModel.Latitude()),
        lng: parseFloat(viewModel.Longitude())
    };

    initializeDirectoryMap("map-background", userPosition, viewModel.Members());

    if (!addressPickerLoaded) {
        initializeAddressPicker();
    }
}

function initializeDirectoryMap(mapElementId, userPosition, members) {
    bounds = new google.maps.LatLngBounds();
    geocoder = new google.maps.Geocoder();
    map = new google.maps.Map(document.getElementById(mapElementId));
    infoWindow = new google.maps.InfoWindow();

    // Draw star at the focal point
    if (userPosition && userPosition.lat && userPosition.lng) {
        createMarker(userPosition, true);
        bounds.extend(userPosition);
        filterCircle = new google.maps.Circle({
            strokeColor: "#FF0000",
            strokeOpacity: 0.5,
            strokeWeight: 2,
            fillColor: "#FF0000",
            fillOpacity: 0.15,
            map,
            center: userPosition,
            radius: viewModel.Radius() * 1609.34
        });
    }

    // Draw pin for each company
    members.forEach(function (member) {
        if (member.ShippingAddress.Lat && member.ShippingAddress.Long) {
            var pos = { lat: member.ShippingAddress.Lat, lng: member.ShippingAddress.Long };
            var content = $("#member_" + member.ID).html();
            markers[member.ID] = createMarker(pos, false, content);
            bounds.extend(pos);
        }
    });

    map.fitBounds(bounds);
    setTimeout("setZoomLevel(" + getZoomLevel() + ");", 100);
}

function getZoomLevel() {
    var zoomLevel = 17;
    if (filterCircle) {
        var radius = filterCircle.getRadius() + filterCircle.getRadius() / 2;
        var scale = radius / 500;
        zoomLevel = (16 - Math.log(scale) / Math.log(2));
    }
    return zoomLevel;
}

var counter = 0;
function setZoomLevel(maxZoomLevel) {
    if (map.getZoom() > maxZoomLevel)
        map.setZoom(maxZoomLevel);
    while (counter < 4 && map.getZoom() != maxZoomLevel) {
        counter++;
        setTimeout("setZoomLevel(" + maxZoomLevel + ");", 1000);
    }
}

function createMarker(latlng, primary, content) {
    var star = {
        path: 'M 125,5 155,90 245,90 175,145 200,230 125,180 50,230 75,145 5,90 95,90 z',
        fillColor: 'blue',
        fillOpacity: 1,
        scale: .075,
        strokeColor: 'blue',
        strokeWeight: 1
    };
    var marker = new google.maps.Marker({ position: latlng, map: map, icon: primary ? star : null });

    if (content) {
        marker.addListener('click', function () {
            openInfoWindow(content, marker);
        });
    }

    return marker;
}

function initializeAddressPicker() {
    var x = new google.maps.places.Autocomplete($("#AddressPicker")[0], { types: ['geocode'] });
    google.maps.event.addListener(x, 'place_changed', function () {
        var place = x.getPlace();
        if (place && place.geometry) {
            var pos = {
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            };

            viewModel.Latitude(pos.lat);
            viewModel.Longitude(pos.lng);

            reloadListing();
        }
        else {
            clearAddressInput();
        }
    });

    $("#AddressPicker").focus(function () {
        $(this).attr("autocomplete", "nope");
    });

    $("#AddressPicker").change(function () {
        viewModel.Latitude(null);
        viewModel.Longitude(null);
    });
}

function clearAddressInput() {
    var addressInput = $("#AddressPicker")[0];
    $("#Latitude").val("");
    $("#Longitude").val("");
    setTimeout(function () { addressInput.value = ""; addressInput.focus(); }, 10);
}

function getUserLocation(callback) {
    // Try HTML5 geolocation.
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            var pos = {
                lat: position.coords.latitude,
                lng: position.coords.longitude
            };

            if (typeof callback === "function") {
                callback(pos);
            }
        }, function () {
            ShowCustomAlert('Sorry, we were unable to determine your position. Please allow location access if you would like to use this feature.');
        });
    } else {
        // Browser doesn't support Geolocation
        ShowCustomAlert('Sorry, it appears your browser doesn\'t support the locate feature.');
    }
}

function getUserAddress(callback) {
    getUserLocation(function (position) {
        getAddressFromPosition(position, callback);
    });
}

function getAddressFromPosition(position, callback) {
    geocoder.geocode({ location: position }, function (data) {
        var address = data[0].formatted_address;
        callback(address, position);
    });
}

function getPositionFromAddress(address, callback) {
    geocoder.geocode({ address: address }, function (data, status) {
        var position = status == "OK" ? data[0].geometry.location : null;
        callback(position, status);
    });
}

function openInfoWindow(content, marker) {
    infoWindow.close();
    infoWindow.setContent(content);
    infoWindow.open(map, marker);
    setTimeout(function () {
        $('[data-toggle="tooltip"]').tooltip();
    }, 500);
}

$(function () {
    ko.applyBindings(viewModel);

    $("#search-box").keydown(function (e) {
        var key = e.charCode || e.keyCode || 0;
        if (key == 13) {
            $("#search-go").click();
        }
    });

    $("#filter-menu").keydown(function (e) {
        var key = e.charCode || e.keyCode || 0;
        if (key == 13) {
            $("#filter-menu-wrapper").addClass("open");
        }
    });

    $(document).on('focusin', () => {
        if (!$("#filter-menu-wrapper").hasClass("open")) {
            return;
        }

        let activeElement = document.activeElement;

        const elements = [
            "filter-menu",
            "df-member-types",
            "member-type-filter",
            "special-offers-ddl",
            "df-group-filter",
            "df-committee-filter",
            "df-multi-select-filter",
            "CityFilter",
            "StateFilter",
            "ZipCodeFilter",
            "CountyFilter",
            "CountryFilter"
        ];

        if (!elements.includes(activeElement.id) && !activeElement.id.includes("GroupFilter_") && !activeElement.id.includes("CommitteeFilter_") && !activeElement.id.includes("CustomField_") && !activeElement.id.includes("MultiSelectFilter_") && $(activeElement).parents('.selectize-input').length === 0) {
            $("#filter-menu-wrapper").removeClass("open");
        }
    });

    $("#filter-menu").on("keypress click", function () {
        $(this).attr("aria-expanded", !viewModel.FilterToggle());
        viewModel.FilterToggle(!viewModel.FilterToggle());
    })

    $("#deep-link").click(function () {
        $(this).select();

        $(this).mouseup(function () {
            $(this).unbind("mouseup");
            return false;
        });
    });

    $("#member-type-filter").selectize({
        plugins: ['remove_button'],
        placeholder: "All",
        onChange: function (value) {
            viewModel.MemberTypeIDs(value ? value.join() : ""); // Produces comma separated list of Member Type IDs

            if (structureFieldType === "MemberType") {
                if (!value || value.length !== 1) {
                    viewModel.StructureFieldName("");
                    viewModel.StructureFieldValue("");
                }
                else if (value.length === 1) {
                    viewModel.StructureFieldName("Member Type");
                    viewModel.StructureFieldValue($("#member-type-filter").text());
                }
            }
        },
        load: function (query, callback) {
            if (this.currentResults.items.length) {
                $("#member-type-filter-alert").text(this.currentResults.items.length + " suggestions found");
            } else {
                $("#member-type-filter-alert").text("No suggestions found");
            }

            callback();
        }
    });

    $(".custom-field-multi").selectize({
        plugins: ['remove_button'],
        placeholder: "All"
    });

    $(".custom-filter").change(function () {
        if (structureFieldType === "CustomField") {
            var fieldName = $(this).prev("label").text();

            // This needs to be the text of the selected option when it has a value, not the value itself
            var optionSelected = $(this).find('option[value!=""]:selected').text().trim();

            if ($(this).data("structured")) {
                if (!optionSelected) {
                    viewModel.StructureFieldName("");
                    viewModel.StructureFieldValue("");
                }
                else {
                    viewModel.StructureFieldName(fieldName);
                    viewModel.StructureFieldValue(optionSelected);
                }
            }
        }

        reloadListing();
    });

    $("#Radius").change(function () {
        if (viewModel.Latitude() && viewModel.Longitude()) {
            reloadListing();
        }
    });

    $("#locate-user-button").click(function () {
        getUserAddress(function (address, position) {
            viewModel.Latitude(position.lat);
            viewModel.Longitude(position.lng);
            viewModel.Address(address);
            reloadListing();
        });
    });

    window.SwitchToListView = function SwitchToListView() {
        viewModel.MapView(false);
        window.location.href = GetDeepLinkUrl();
    };

    window.SwitchToMapView = function SwitchToMapView() {
        viewModel.MapView(true);
        window.location.href = GetDeepLinkUrl();
    };

    window.SwitchToCategoryView = function SwitchToCategoryView() {
        window.location.href = baseUrl + "?CategoryView=true";
    };

    $(".c-directory-map-view-member-list > div > [id^=member_]").hover(
        function () {
            openInfoWindow($(this).html(), markers[$(this).data("member-id")]);
        },
        function () {
            infoWindow.close();
        });

    $(document).on('click', '.o-filter-bar .dropdown-menu', function (e) {
        e.stopPropagation();
    });

    if (breakpoint().indexOf("mobile") != -1) {
        var containerWidth = $(".directory").outerWidth();
        $("#map-background").width(containerWidth).height(containerWidth);
    }

    $('[data-toggle="tooltip"]').tooltip();

    reloadListing(true);
});